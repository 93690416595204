<template>
  <div>
    <a-row style="background-color: #fff; padding: 8px 12px; margin-bottom: 8px">
      <a-col :span="8">
        <div style="margin: 8px; font-size: 16px">
          <span>统计日期: 2022-06-16</span>
        </div>
      </a-col>
      <a-col :span="8">
        <div style="text-align: center; background-color: #fff; font-size: 26px; font-weight: bold">
          WMS拣货单据看板
        </div>
      </a-col>
      <a-col :span="8">
        <div style="margin: 8px; font-size: 16px">
          <span>当前时间: 2022-06-16 09:00:00</span>
          <span style="float: right">
            <a-icon type="fullscreen" style="font-size: 20px; color: #1890ff" />
          </span>
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[8, 8]">
      <a-col :span="8">
        <a-card :bordered="false" size="small" style="height: 280px">
          <template slot="title">TOP10拣货产品</template>
          <div id="container1" />
        </a-card>
      </a-col>
      <a-col :span="16">
        <a-card :bordered="false" size="small" style="height: 280px">
          <template slot="title">实时拣货量</template>
          <template slot="extra">
            <a-range-picker
              :value="['2022-06-17 08:00:00', '2022-06-17 20:00:00']"
              size="small"
              :allowClear="false"
              style="width: 320px"
              :showTime="true"
            />
          </template>
          <div id="container2" />
        </a-card>
      </a-col>
    </a-row>

    <a-row>
      <a-card :bordered="false" size="small" :body-style="{ padding: '0' }">
        <a-table :columns="columns" :data-source="data" size="small" :pagination="false" />
      </a-card>
    </a-row>
  </div>
</template>

<script>
import { Pie, Line } from "@antv/g2plot";

export default {
  data() {
    return {
      container1: null,
      container2: null,

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
          width: 60,
        },
        { title: "单据日期", dataIndex: "date" },
        { title: "单据编号", dataIndex: "number" },
        { title: "客户", dataIndex: "client" },
        { title: "仓库", dataIndex: "warehouse" },
        { title: "数量", dataIndex: "quantity" },
        { title: "状态", dataIndex: "status" },
      ],
      data: [
        {
          date: "2022-06-10",
          number: "DO202206100002",
          client: "保立佳",
          warehouse: "总仓",
          quantity: "13",
          status: "拣货中",
        },
        {
          date: "2022-06-10",
          number: "DO202206100001",
          client: "保立佳",
          warehouse: "总仓",
          quantity: "41",
          status: "拣货中",
        },
        {
          date: "2022-06-07",
          number: "DO202206070001",
          client: "保立佳",
          warehouse: "总仓",
          quantity: "33",
          status: "拣货中",
        },
        {
          date: "2022-06-06",
          number: "DO202206060001",
          client: "控道",
          warehouse: "总仓",
          quantity: "23",
          status: "拣货中",
        },
        {
          date: "2022-06-05",
          number: "DO202206050003",
          client: "控道",
          warehouse: "总仓",
          quantity: "67",
          status: "拣货中",
        },
        {
          date: "2022-06-05",
          number: "DO202206050002",
          client: "控道",
          warehouse: "总仓",
          quantity: "128",
          status: "拣货中",
        },
        {
          date: "2022-06-05",
          number: "DO202206050001",
          client: "控道",
          warehouse: "总仓",
          quantity: "54",
          status: "拣货中",
        },
        {
          date: "2022-06-04",
          number: "DO202206040002",
          client: "控道",
          warehouse: "总仓",
          quantity: "23",
          status: "拣货中",
        },
        {
          date: "2022-06-04",
          number: "DO202206040001",
          client: "控道",
          warehouse: "总仓",
          quantity: "34",
          status: "拣货中",
        },
      ],
    };
  },
  methods: {
    initData() {
      this.container1 = new Pie("container1", {
        data: [
          { material: "HYDROXY-TEMPO 5%", quantity: 639 },
          { material: "纯碱", quantity: 661 },
          { material: "BLJ-532A塑", quantity: 454 },
          { material: "BLJ-501铁", quantity: 243 },
          { material: "BLJ-780铁", quantity: 412 },
          { material: "塑吨包", quantity: 232 },
          { material: "BLJ-816塑", quantity: 492 },
          { material: "BLJ-709D塑", quantity: 549 },
          { material: "铁吨包", quantity: 410 },
          { material: "其他", quantity: 3212 },
        ],
        meta: {
          country: { alias: "产品", range: [0, 1] },
          value: { alias: "数量" },
        },
        appendPadding: 10,
        angleField: "quantity",
        colorField: "material",
        height: 200,
      });

      this.container2 = new Line("container2", {
        data: [
          { time: "08:00", quantity: 13 },
          { time: "09:00", quantity: 32 },
          { time: "10:00", quantity: 21 },
          { time: "11:00", quantity: 13 },
          { time: "12:00", quantity: 21 },
          { time: "13:00", quantity: 31 },
          { time: "14:00", quantity: 27 },
          { time: "15:00", quantity: 15 },
          { time: "16:00", quantity: 33 },
          { time: "17:00", quantity: 44 },
          { time: "18:00", quantity: 27 },
          { time: "19:00", quantity: 11 },
          { time: "20:00", quantity: 21 },
        ],
        xField: "time",
        yField: "quantity",
        label: {},
        meta: { time: { alias: "时间" }, quantity: { alias: "拣货数量" } },
        height: 200,
      });

      this.container1.render();
      this.container2.render();
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="less" scoped>
.smallCard {
  width: 33%;
  text-align: center;
  box-shadow: 0 0 0 #888888;
}
.chartTitle {
  font-size: 14px;
  font-weight: bold;
}
.number {
  font-size: 24px;
  height: 50%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.intro {
  height: 50%;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}
.percent {
  height: 45px;
  font-size: 20px;
  text-align: left;
  color: #1890ff;
  &::before {
    content: "占用率:";
    font-size: 12px;
  }
  &::after {
    content: "%";
    font-size: 12px;
  }
}
</style>
